import { Button, Grid, List, ListItemButton, ListItemText } from "@mui/material";
import { NavigationInterface } from "../interface/interface";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function StaticHorizontalMenu(navigationData: NavigationInterface[], onClick: any, currentPath?: string) {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigation = useNavigate();

    const x = (anchorEl?.offsetLeft || 0);
    const y = (anchorEl?.offsetTop || 0) + (anchorEl?.offsetHeight || 0) - 1;

    function getSubMenu() {
        let item = navigationData.filter(item => item.path === anchorEl?.getAttribute("data-path"));
        if (item != null && item.length > 0) {
            let subitem = item[0].subitems;
            return (subitem && subitem.length > 0) ? subitem : null;
        }
        return null;
    }

    function getSubListItem() {
        const items = getSubMenu();
        if (items && items.length) {
            return (
                <List key={"subitem-popup"} component="div" disablePadding>
                    {items.map(subitem => (
                        <ListItemButton key={subitem.text} sx={{
                            position: "relative",
                            color: currentPath === subitem.path ? "#00896f" : "#FFF",
                            padding: "10px",
                            '&:hover': {
                                color: "#00896f",
                                cursor: "pointer"
                            }
                        }}
                            onClick={(e) => { onSelection(subitem) }}
                        >
                            <ListItemText sx={{ margin: 0, textTransform: "capitalize" }}>{subitem.text}</ListItemText>
                        </ListItemButton>
                    ))
                    }
                </List>
            );
        }
        else {
            return (<></>);
        }
    }

    function onSelection(item: NavigationInterface) {
        if (item.subitems && item.subitems.length > 0) {

        } else {
            setOpen(false);
            setAnchorEl(null)
            navigation(item.path);
        }
    }

    function isActive(path: string, cPath?: string) {
        if (cPath && cPath.split("/").length === 3) {
            let cPathArray = cPath.split("/")
            cPathArray.pop()
            return path === cPathArray.join("/")
        }
        else {
            return path === cPath;
        }
    }

    return (
        <>
            {navigationData.map(item => {
                return (<Button
                    data-path={item.path}
                    onMouseEnter={(e) => { setOpen(true); setAnchorEl(e.currentTarget) }}
                    sx={{
                        position: "relative",
                        textDecoration: "none",
                        display: "inline-block",
                        color: isActive(item.path, currentPath) ? "#00896f" : "#fff",
                        '&:hover': {
                            color: "#00896f",
                            cursor: "pointer"
                        }
                    }}
                    onClick={() => { onSelection(item); }}>
                    {item.text}
                </Button>)
            })}
            {(open && getSubMenu() != null) &&
                <Grid
                    onMouseLeave={(e) => { setOpen(false); setAnchorEl(null); }}
                    component="div"
                    style={{
                        background: "#000",
                        zIndex: 99,
                        minWidth: "200px",
                        position: "absolute",
                        left: x,
                        top: y,
                        // boxShadow: "2px 1px 10px #999",
                        transformOrigin: "left",
                        transform: open ? "scaleX(1)" : "scaleX(0)",
                        transitionDuration: "300ms",
                        transitionBehavior: "ease-out",
                    }}>
                    {getSubListItem()}
                </Grid>
            }
        </>
    );
}

export default StaticHorizontalMenu;