import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import Page from './pages/page';
import Header from './components/header';
import { ThemeContextProvider } from './theme/ThemeContextProvider';
import { Footer } from './components/footer';

function App() {
  return (
    <ThemeContextProvider>
    <HelmetProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path=":pageName" element={<Page />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
    </ThemeContextProvider>
  );
}

export default App;
