import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useThemeContext } from '../theme/ThemeContextProvider';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import headerJson from './../json/header.json';
import { NavigationInterface } from '../interface/interface';
import StaticListMenu from './listmenu-static';
import StaticHorizontalMenu from './horizontalmenu-static';

interface HeaderContentInterface {
  fb: string;
  linkedin: string;
  twitter: string;
  email: string;
  phone: string;
  logoimg: string;
  logotxt: string;
}
interface HeaderInterface {
  navigation: NavigationInterface[];
  content: HeaderContentInterface;
}

const Header: React.FC = () => {
  const headerData = headerJson as HeaderInterface;
  const { toggleColorMode, mode } = useThemeContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <AppBar position="static" sx={
        {
          backgroundColor: mode === "light" ? "#fff" : "#1d1d1d",
          color: mode === "light" ? "#1d1d1d" : "#ffsf",
          zIndex: "2"
        }
      }>
        <Toolbar>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => { setMenuOpen(!menuOpen); }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {headerData.content.logoimg &&
            <Link to="/"><img src={headerData.content.logoimg} height={"50px"} alt="logo" /></Link>
          }
          {headerData.content.logotxt &&
            <Link to="/" style={{ textDecoration: "none", color: "#00896f" }}><Typography variant="h6" component="div">
              {headerData.content.logotxt}
            </Typography></Link>
          }
          <Box sx={{ flexGrow: 1 }}></Box>
          {headerData.content.email && <>
            <IconButton sx={{ fontSize: "unset", color: "#00896f", border: "1px dotted #00896f", marginRight: "10px", display: { xs: "none", md: 'block' } }}>
              <EmailIcon sx={{ verticalAlign: "bottom", fontSize: "unset", lineHeight: 1, width: "25px", height: "25px" }} />
            </IconButton>
            <Typography variant="body1" component="div" sx={{ fontWeight: "bold", marginRight: "20px", lineHeight: 1, display: { xs: "none", md: 'block' } }}>
              EMAIL US <br /><span style={{ color: "#00896f", fontSize: "13px" }}>{headerData.content.email}</span>
            </Typography>
          </>}
          {headerData.content.phone && <>
            <IconButton sx={{ fontSize: "unset", color: "#00896f", border: "1px dotted #00896f", marginRight: "10px", display: { xs: "none", md: 'block' } }}>
              <LocalPhoneIcon sx={{ verticalAlign: "bottom", fontSize: "unset", lineHeight: 1, width: "25px", height: "25px" }} />
            </IconButton>
            <Typography variant="body1" component="div" sx={{ fontWeight: "bold", marginRight: "20px", lineHeight: 1, display: { xs: "none", md: 'block' } }}>
              <strong>CALL US</strong><br /><span style={{ color: "#00896f", fontSize: "13px" }}>{headerData.content.phone}</span>
            </Typography>
          </>}
          {headerData.content.fb &&
            <IconButton href={headerData.content.fb} sx={{ "&:hover": { color: "#23527c" }, display: { xs: "none", md: 'block' } }}>
              <FacebookIcon />
            </IconButton>}
          {headerData.content.linkedin && <IconButton href={headerData.content.linkedin} sx={{ "&:hover": { color: "#337ab7" }, display: { xs: "none", md: 'block' } }}>
            <LinkedInIcon />
          </IconButton>}
          {headerData.content.twitter && <IconButton href={headerData.content.twitter} sx={{ "&:hover": { color: "#000" }, display: { xs: "none", md: 'block' } }}>
            <XIcon />
          </IconButton>}
          &nbsp;&nbsp;
          <IconButton sx={{ ml: 1, display: "none" }} onClick={toggleColorMode} color="inherit">
            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <div id="google_language_translator"></div>
        </Toolbar>
        <Drawer
          anchor="left"
          open={menuOpen}
          onClose={handleMenuClick}
          PaperProps={{ style: { backgroundColor: "#f1f1f1", opacity: 0.9 } }}
          ModalProps={{ style: { backdropFilter: "blur(10px)" } }}
        >
          {StaticListMenu(headerData.navigation, handleMenuClick, location.pathname)}
        </Drawer>
      </AppBar>
      <div style={
        {
          position: "relative",
          margin: "auto",
          backgroundColor: "#000",
          color: "#fff"
        }
      }>
        <Box sx={{ display: { xs: 'none', md: 'block' } }} textAlign={"center"} >
          {StaticHorizontalMenu(headerData.navigation, handleMenuClick, location.pathname)}
        </Box>
      </div>
    </>
  );
};

export default Header;