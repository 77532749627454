import { Grid, Paper, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

interface GridInterfaceTara {
    img: string;
    head?: string;
    url: string;
}

export default function GridStyleTara(data: any) {

    var ndata = data.data as GridInterfaceTara[];

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "transparent",
        textAlign: 'left',
        boxShadow: "none",
        margin: "15px",
        borderRadius: "0px",
    }));

    const GridItem = (item: GridInterfaceTara) => {
        return (
            <Grid item xs={12} sm={6} lg={3} key={item.head}>
                <Link to={item.url} style={{ textDecoration: "none"}}>
                    <Item sx={{ textAlign: "left", border: "1px solid #00896f", backgroundColor: "#00896f", '&:hover' : { border: "1px solid #4d4d4d", backgroundColor: "#4d4d4d" } }}>
                        <img src={item.img} alt={item.head} style={{ width: "100%", verticalAlign: "bottom" }} />
                        {item.head && <Typography variant="body1" component="div" sx={{
                            flexGrow: 1,
                            textAlign: "left",
                            color: "#fff",
                            padding: "5px",
                        }}>
                            <span dangerouslySetInnerHTML={{__html: item.head}} />
                        </Typography>
                        }
                    </Item>
                </Link>
            </Grid>
        );
    }

    return (
        <div style={{ backgroundColor: data.backgroundcolor, paddingTop: "30px", paddingBottom: "30px" }}>
            <Typography variant="h5" component="div" sx={{
                flexGrow: 1,
                marginBottom: "30px",
                textAlign: "center",
                fontWeight: "bold",
                position: "relative",
                '&::after': {
                    content: '""',
                    height: "2px",
                    position: "absolute",
                    background: "#00896f",
                    left: "50%",
                    marginLeft: "-35px",
                    bottom: "-15px",
                    width: "70px"
                }
            }}>
                {data.title}
            </Typography>
            <Grid container maxWidth={"1300px"} margin={"auto"}>
                {
                    ndata.map(dataitem => <GridItem img={dataitem.img} head={dataitem.head} url={dataitem.url}  />)
                }
            </Grid>
        </div>
    );
};
