import React from 'react';
import { ComponentData, ComponentType, PageInfo } from '../interface/interface';
import GridStyleSara from './grid-style-sara';
import GridStyleTara from './grid-style-tara';
import GridStyleMara from './grid-style-mara';
import CarouselStyleSilk from './carosel-style-slik';
import BannerStyleRock from './banner-style-rock';


const DynamicRenderer = (pagedata: PageInfo) => {

  const renderComponent = (componentData: ComponentData) => {
    let type = ComponentType[componentData.type]
    switch (type) {
      case ComponentType.GRID_STLYE_TARA:
        return <GridStyleTara {...componentData}/>;
      case ComponentType.GRID_STLYE_SARA:
        return <GridStyleSara {...componentData}/>;
      case ComponentType.GRID_STLYE_MARA:
        return <GridStyleMara {...componentData}/>;
      case ComponentType.CAROUSEL_STYLE_SILK:
        return <CarouselStyleSilk {...componentData}/>;
      case ComponentType.BANNER_STYLE_ROCK:
        return <BannerStyleRock {...componentData}/>;
      default:
        return null; // Handle unknown types or fallback
    }
  };

  return (
    <div>
      {pagedata.components.map((item: ComponentData, index: React.Key | null | undefined) => (
        <div key={index}>
          {renderComponent(item)}
        </div>
      ))}
    </div>
  );
};

export default DynamicRenderer;
