import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationInterface } from "../interface/interface";

function StaticListMenu(navigationData: NavigationInterface[], onClick: any, currentPath?: string){
  return (
    <Box
        sx={{ width: 250 }}
        component="nav"
    >
        <List role="menu" key="drawermenu">
            {navigationData.map((item) => (StaticListMenuItem(item, onClick, currentPath)))}
        </List>
    </Box>
    );
}

function StaticListMenuItem(item: NavigationInterface, onClick: any, currentPath?: any) {
  
  const isCurrenpathPrensentInSubmenu = (itemlist?: NavigationInterface[]) => {
    let el = null;
    if(itemlist != null )
      el = itemlist.filter(i => i.path === currentPath);
    return (el != null && el.length > 0)
  }
  
  const [open, setOpen] = useState(isCurrenpathPrensentInSubmenu(item.subitems));
  const navigate = useNavigate();

  const handleClick = (item: NavigationInterface) => {
    if (item.subitems && item.subitems.length > 0) {
      setOpen(!open);
    }
    else {
      onClick && onClick();
      navigate(item.path);
    }
  };

  function isActive(path: string, cPath?: string){
    if(cPath && cPath.split("/").length === 3){
        let cPathArray = cPath.split("/")
        cPathArray.pop()
        return path === cPathArray.join("/")
    }
    else{
        return path === cPath;
    }
}

  return (
    <>
      <ListItemButton
        onClick={() => { handleClick(item) }}
        key={item.text}
        sx={{
          position: "relative",
          backgroundColor: isActive(item.path, currentPath) ? "#00896f" : "",
          color: isActive(item.path, currentPath) ? "#fff": "#000",
          '&:hover': {
            backgroundColor: "#00896f",
            color: "#fff",
            cursor: "pointer"
          },
          '&::after': {
            content: '""',
            height: "1px",
            position: "absolute",
            background: "#00896f",
            left: "10px",
            right: "10px",
            bottom: "0px",
            opacity: 0.1
          }
        }}
      >
        <ListItemText primary={item.text} />
        {item.subitems && item.subitems.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)
        }
      </ListItemButton>
      {item.subitems && item.subitems.length > 0 &&
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List key={item.text + "subitem"} component="div" disablePadding>
            {item.subitems.map(subitem => (
              <ListItemButton key={subitem.text} sx={{
                pl: 4,
                position: "relative",
                backgroundColor: currentPath === subitem.path ? "#00896f" : "",
                color: currentPath === subitem.path ? "#fff": "#000",
                '&:hover': {
                  backgroundColor: "#00896f",
                  color: "#fff",
                  cursor: "pointer"
                },
                '&::after': {
                  content: '""',
                  height: "1px",
                  position: "absolute",
                  background: "#00896f",
                  left: "10px",
                  right: "10px",
                  bottom: "0px",
                  opacity: 0.1
                }
              }}
              onClick={() => { handleClick(subitem) }} 
              >
                <ListItemText>{subitem.text}</ListItemText>
              </ListItemButton>
            ))
            }
          </List>
        </Collapse>
      }
    </>
  );
}

export default StaticListMenu;