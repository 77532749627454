import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Meta from '../components/meta';
import DynamicRenderer from '../components/component';
import { PageInfo } from '../interface/interface';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';


const Page: React.FC = () => {
  const { pageName } = useParams<{ pageName: string }>();
  const [content, setContent] = useState<PageInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        // const content = await import(`/content/${pageName}.json`);
        // setContent(content);
        const response = await fetch(`/content/${pageName}.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: PageInfo = await response.json();
        setContent(data);
        setError(null);
      } catch (error) {
        setError(new Error('Page content not found'));
      } finally {
        setLoading(false);
      }
    };

    fetchPageContent();
  }, [pageName]);
  if (loading) return (<div style={{ height: "80vh", textAlign: "center" }}>
    <CircularProgress style={{ position: "fixed", top: "50%" }} />
  </div>);
  if (error) return (<div style={{textAlign: "center" }}>
    <div style={{ padding: "50px" }} >
        <Typography variant='h3'>We can't found the page <br/>that you're looking for</Typography>
        <Typography variant='h1' style={{color: "#00896f"}}>404</Typography>
    </div>
  </div>);
  if (!content) return <div>No content found</div>;
    
  return (
    <div>
      <Meta title={content.title} description={content.description} />
      <DynamicRenderer {...content} />
    </div>
  );
};

export default Page;
