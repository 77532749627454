import { Divider, Grid, IconButton, InputBase, ListItemIcon, MenuItem, MenuList, Paper, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import footerJson from './../json/footer.json';
import { useState } from "react";

interface FooterNavigationItemInterface{
    text: string;
    url: string;
}

interface FooterNavigationInterface{
    head: string;
    items: FooterNavigationItemInterface[];
}

interface FooterNewsLetterInterface{
    head: string;
    desc: string;
}

interface FooterStayConnectedLocationInterface{
    head: string;
    location: string;
}

interface FooterStayConnectedItemInterface{
    location: FooterStayConnectedLocationInterface;
    phone: string;
    email: string;
}

interface FooterStayConnectedInterface{
    head: string;
    items: FooterStayConnectedItemInterface[];
}

interface FooterInterface{
    navigation: FooterNavigationInterface;
    newsletter?: FooterNewsLetterInterface;
    stayconnected: FooterStayConnectedInterface;
    copyright: string;
}

export const Footer = () => {
    const [email, setEmail] = useState("");
    const [emailerror, setEmailError] = useState("");
    var data = footerJson as FooterInterface;

    var getNavItem = (navItem: FooterNavigationItemInterface) => {
        return (
            <MenuItem key={navItem.text} style={{padding:"2px 0px"}}>
                <ListItemIcon style={{minWidth: "20px"}}>
                    <ArrowForwardIosIcon style={{fontSize:"13px"}} />
                </ListItemIcon>
                <span>{navItem.text}</span>
            </MenuItem>
        );
    }

    var getConnectedItem = (connectItem: FooterStayConnectedItemInterface, index: number) => {
        let elmts = [];
        if(connectItem.location.head){
            elmts.push(<MenuItem key={index + connectItem.location.head} sx={{'&:hover': { backgroundColor: "transparent", cursor:"unset"}}} style={{padding:"0px"}}>
                <ListItemIcon style={{minWidth: "20px"}}>
                    <PlaceIcon style={{fontSize:"13px"}} />
                </ListItemIcon>
                <strong>{connectItem.location.head}:</strong>
            </MenuItem>)

            elmts.push(<MenuItem key={index + connectItem.location.location} sx={{'&:hover': { backgroundColor: "transparent", cursor:"unset"}}} style={{padding:"0px"}}>
                <ListItemIcon style={{minWidth: "20px"}}>
                </ListItemIcon>
                <span style={{textWrap:"wrap"}}>{connectItem.location.location}</span>
            </MenuItem>)
        }
        if(connectItem.phone){
            elmts.push(<MenuItem key={index + connectItem.phone} sx={{'&:hover': { backgroundColor: "transparent", cursor:"unset"}}} style={{padding:"5px 0px"}}>
                <ListItemIcon style={{minWidth: "20px"}}>
                    <LocalPhoneIcon style={{fontSize:"13px"}} />
                </ListItemIcon>
                <strong>Phone:</strong>&nbsp;{connectItem.phone}
            </MenuItem>)
        }

        if(connectItem.phone){
            elmts.push(<MenuItem key={connectItem.phone} sx={{'&:hover': { backgroundColor: "transparent", cursor:"unset"}}} style={{padding:"5px 0px"}}>
                <ListItemIcon style={{minWidth: "20px"}}>
                    <EmailIcon style={{fontSize:"13px"}} />
                </ListItemIcon>
                <strong>E-mail:</strong>&nbsp;{connectItem.email}
            </MenuItem>)
        }
        return elmts;
    }
    
    var footerSecHead = (txt: string) => {
        return <Typography variant="h6" style={{color: "#00896f"}}><span dangerouslySetInnerHTML={{__html: txt}} /></Typography>
    }

    var handleChange = (e: any) => {
        setEmail(e.target.value);
        setEmailError("")
     }
  
    var keyPress = (e: any) => {
        if(e.keyCode === 13){
            emailNotification(email)
            e.preventDefault()
        }
        return false;
     }

    const validateEmail = (email: string) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      

    var emailNotification = (email: string) => {
        if(validateEmail(email)){
            //TODO: send email
            console.log("sending mail", email)
       }
       else{
            setEmailError("Email is invalid");
       }
    }

    return (
        <div style={{borderTop:"1px solid #ccc", backgroundColor: "#f1f1f1"}}>
            <div style={{padding: "30px 0px"}}>
                <Grid container style={{ margin:"auto", maxWidth:"1300px"}} spacing={0}>
                    {data.newsletter && 
                    <Grid key={data.newsletter.head} item xs={12} sm={12} lg={4}>
                        <div style={{paddingRight: "70px"}}>
                            {footerSecHead(data.newsletter.head)}
                            <Typography variant="body1" style={{padding:"15px 0px"}}><span dangerouslySetInnerHTML={{__html: data.newsletter.desc}} /></Typography>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', margin:"5px 0px", boxShadow: "none", border: emailerror ? "1px solid red":"1px solid #00896f" }}
                                >
                                <InputBase
                                    sx={{ ml: 1, flex: 1, backgroundColor: "transparent"}}
                                    placeholder="Your Email..."
                                    inputProps={{ 'aria-label': 'Enter your email' }}
                                    onKeyDown={keyPress}
                                    onChange={handleChange}
                                />
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton onClick={()=>emailNotification(email)} sx={{ p: '10px', color: "#00896f", borderRadius: 0 }} aria-label="directions">
                                    <EmailIcon/>
                                </IconButton>
                            </Paper>
                            {emailerror && <Typography variant="body1" sx={{color: "red"}}>{emailerror}</Typography>}
                        </div>
                    </Grid>
                    }
                    <Grid key={data.navigation.head} item xs={12} sm={12} lg={data.newsletter ? 3 : 6} style={{padding: "15px"}}>
                        {footerSecHead(data.navigation.head)}
                        <MenuList>
                            {data.navigation.items.map(navitem => getNavItem(navitem))}
                        </MenuList>
                    </Grid>
                    <Grid key={data.stayconnected.head} item xs={12} sm={12} lg={data.newsletter ? 5: 6} style={{padding: "15px"}}>
                        {footerSecHead(data.stayconnected.head)}
                        <MenuList>
                            {data.stayconnected.items.map((connectItem, index) => getConnectedItem(connectItem, index))}
                        </MenuList>
                    </Grid>
                </Grid>
            </div>
            <div style={{backgroundColor: "#4d4d4d", textAlign: "center", color: "#fff", padding: "10px"}}>{data.copyright}</div>
        </div>
    );
}