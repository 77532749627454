import { Typography } from '@mui/material'
import { ComponentData } from '../interface/interface';

interface BannerInterfaceRock{
    name?: string;
    img: string;
}

export default function BannerStyleRock(data: any)
{
    var cData = data as ComponentData;
    var nData = data.data as BannerInterfaceRock;
    return (
        <div key={nData.name} style={{position: "relative", maxWidth: "1300px", height: cData?.properties?.height ? cData?.properties?.height : "100px", margin: "auto"}}>
                <div style={{backgroundImage: 'url("' + nData.img + '")', backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", height: "100%",}}/>
                <div style={{position:"absolute", left: 0, top:0, width: "100%", height: "100%", display:"table"}}>
                    <div style={{display:"table-cell", verticalAlign: "middle", textAlign:"center"}}>
                        { nData.name && 
                            <div style={{display:"inline-block", padding: "10px 15px", backgroundColor: "rgba(0, 137, 111, 1)"}}>
                                <Typography variant='h5' style={{color: "#fff", fontWeight: "bold"}} ><span dangerouslySetInnerHTML={{__html: nData.name}} /></Typography>
                            </div>
                        }
                    </div> 
                </div>
            </div>
    )
}