import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

interface ModalInterfaceJivin{
  img: string;
  title: string;
  desc: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ModalStyleJivin(open: boolean, data: ModalInterfaceJivin, close: any) {

  return (
      <BootstrapDialog
        onClose={close}
        maxWidth={'md'}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} lg={5} >
              <div style={{height: "200px", overflow: "hidden", backgroundImage: 'url("' + data.img + '")', backgroundSize: "cover", backgroundRepeat: "no-repeat",}}>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} lg={7}>
              <Typography variant="h5" component="div" sx={{
                        flexGrow: 1,
                        marginBottom: "20px", textAlign: "left"
                    }}>
                    <span dangerouslySetInnerHTML={{__html: data.title}} />
              </Typography>
              <Typography variant="body1" component="div">
                  <span dangerouslySetInnerHTML={{__html: data.desc}} />
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
  );
}
