import { Grid, Paper, Typography, styled } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from "react-router-dom";
import { ComponentData } from "../interface/interface";
import { useState } from "react";
import ModalStyleJivin from "./modal-style-jivin";

interface GridInterfaceMara {
    img: string;
    head: string;
    desc: string;
    url: string;
}

export default function GridStyleMara(data: any) {

    const cdata = data as ComponentData;
    const isExpand = cdata?.properties?.expand === "true" ? true : false;
    const isQuickView = cdata?.properties?.quickview === "true" ? true : false;
    const dataLength = cdata?.properties?.datalength || 350;
    const maxline = cdata?.properties?.maxline || '7';
    const navigate = useNavigate();
    const ndata = data.data as GridInterfaceMara[];
    const [modalData, setModalData] = useState({img: "", title: "", desc: ""});
    const [showModel, setShowModel] = useState(false);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "transparent",
        textAlign: 'left',
        boxShadow: "none",
        padding: "15px"
    }));

    const GridItem = (item: GridInterfaceMara) => {

        const [isVisible, setIsVisible] = useState(false);

        return (
            <Grid item xs={12} sm={6} lg={3} key={item.head}>
                <Item style={{ textAlign: "left" }}>
                    <Typography variant="h5" component="div" sx={{
                        flexGrow: 1,
                        marginBottom: "20px", textAlign: "left"
                    }}>
                        <span dangerouslySetInnerHTML={{__html: item.head}} />
                    </Typography>
                    <div style={{marginBottom: "15px", height: "200px", overflow: "hidden", backgroundImage: 'url("' + item.img + '")', backgroundSize: "cover", backgroundRepeat: "no-repeat",}}>
                    </div>
                    <Typography variant="body1" component="div" sx={ isVisible ? {
                        textAlign: "justify",
                    } : {
                        flexGrow: 1, overflow: 'hidden',
                        textAlign: "justify",
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: maxline,
                        WebkitBoxOrient: 'vertical',
                    }}>
                        <span dangerouslySetInnerHTML={{__html: item.desc}} />
                    </Typography>
                    {item.desc.length >= dataLength &&
                        <Typography variant="body1" component="div" onClick={() => {
                            if(isExpand){
                                setIsVisible(!isVisible)
                            }
                            else if(isQuickView){
                                setModalData({"img": item.img, "title": item.head, "desc": item.desc});
                                setShowModel(true)
                            }
                            else {
                                navigate(item.url)
                            }
                        }} sx={{
                            flexGrow: 1,
                            textAlign: "left",
                            marginTop: "10px",
                            fontStyle: "italic",
                            position: 'relative',
                            color: isVisible ? "#00896f" : "#000000",
                            cursor: "pointer",
                            display: "inline-block",
                            fontSize: "14px",
                            '&::after': {
                                content: '""',
                                height: "2px",
                                position: "absolute",
                                background: "#00896f",
                                left: "-9px",
                                right: "-25px",
                                bottom: "-10px",
                            },
                            '&:hover': {
                                color: "#00896f",
                            }
                        }}>
                            {isVisible ? "Read Less" :  "Read More" } <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                        </Typography>
                    }
                </Item>
            </Grid>
        );
    }

    return (
        <div style={{ backgroundColor: data.backgroundcolor, paddingTop: "30px", paddingBottom: "30px" }}>
            <Typography variant="h5" component="div" sx={{
                flexGrow: 1,
                marginBottom: "30px",
                textAlign: "center",
                fontWeight: "bold",
                position: "relative",
                '&::after': {
                    content: '""',
                    height: "2px",
                    position: "absolute",
                    background: "#00896f",
                    left: "50%",
                    marginLeft: "-35px",
                    bottom: "-15px",
                    width: "70px"
                }
            }}>
                <span dangerouslySetInnerHTML={{__html: data.title}} />
            </Typography>
            <Grid container maxWidth={"1300px"} margin={"auto"}>
                {
                    ndata.map(dataitem => <GridItem img={dataitem.img} head={dataitem.head} desc={dataitem.desc} url={dataitem.url}  />)
                }
            </Grid>
            {ModalStyleJivin(showModel, modalData, function(){ setShowModel(!showModel)})}
        </div>
    );
};
