import Carousel from 'react-material-ui-carousel'
import { Button, Typography } from '@mui/material'
import { Link } from '@mui/icons-material';
import { ComponentData } from '../interface/interface';

interface CarouselInterfaceSilk{
    name?: string;
    desc?: string;
    img: string;
    url?: string;
    buttontxt?: string;
    properties?: any;
}

export default function CarouselStyleSilk(data: any)
{
    var cdata = data as ComponentData;
    var ndata = data.data as CarouselInterfaceSilk[];

    var getCaroselItem = (carouselItem: CarouselInterfaceSilk) => {
        return (
            <div key={carouselItem.name} style={{position: "relative", height: "100%"}}>
                <div style={{backgroundImage: 'url("' + carouselItem.img + '")', backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", height: "100%",}}/>
                <div style={{position:"absolute", left: 0, top:0, width: "100%", height: "100%", display:"table"}}>
                    <div style={{display:"table-cell", verticalAlign: "middle", textAlign:"center"}}>
                        { ( carouselItem.name || carouselItem.desc ) &&
                        <div style={{display:"inline-block", padding: "15px 30px", backgroundColor: "rgba(0, 137, 111, 0.9)"}}>
                            { carouselItem.name && <Typography variant='h5' color={"#fff"} ><span dangerouslySetInnerHTML={{__html: carouselItem.name}} /></Typography> }
                            { carouselItem.desc && <Typography variant='body1' color={"#fff"}><span dangerouslySetInnerHTML={{__html: carouselItem.desc}} /></Typography> }
                            {
                                carouselItem.buttontxt && (
                                    <Link to={carouselItem.url}>
                                        <Button >
                                            {carouselItem.buttontxt}
                                        </Button>
                                    </Link>
                                )
                            }
                        </div>
                        }
                    </div> 
                </div>
            </div>
        );
    }

    return (
        <div style={{maxWidth:'1300px', width: "100%", margin: "auto"}}>
            <Carousel 
                height={cdata?.properties?.height ? cdata?.properties?.height : "300px"}
                animation='slide'
                navButtonsProps={{
                    style: {
                        borderRadius: 0,
                        backgroundColor: "#00896f",
                    }
                }}    
                indicators={false}>
                {
                    ndata.map( (item, i) => getCaroselItem(item) )
                }
            </Carousel>
        </div>
    )
}